import React from 'react';
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import useMatchedQuery from '../hooks/useMatchedQuery';

import GamesLayout from '../components/GamesLayout';
import GameControlNavigator from '../components/GameControlNavigator';
import SEO from '../components/SEO';

const Spacer = () => {
  const NAVIGATION_HEIGHT = 32;
  return (
    <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
  )
};

const Page = (props) => {
  const { data, pageContext } = props;
  const { allContentfulGame, seo, site } = data


  const city = props.pageContext.city;
  const games = allContentfulGame.edges.map((g) => g.node);
  const totalCount = allContentfulGame.totalCount;
  const matchedQuery = useMatchedQuery()
  const image = getSrc(seo.image)

  const description = seo.description.description.replace('[[number]]', totalCount) 
  return (
    <React.Fragment>
      <SEO {...seo} {...pageContext} description={description} pagePath={`/${city}/juegos`} seo={{...seo, image}} siteUrl={site.siteMetadata.siteUrl}/>
      <GamesLayout {...pageContext} displayContact={false} displayDataFiscal={false}>
        <Spacer />
        <GameControlNavigator {...props} height={'100vh'} city={city} preloaded={{games, totalCount}} matchedQuery={matchedQuery} />
      </GamesLayout>
    </React.Fragment>
  )
}

export default Page;

export const query = graphql`
  query($limit: Int=12, $skip: Int=0, $city: String="ba") {
      seo: contentfulComponentSeo(name: {eq: "games"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: FIXED,
            width: 600,
            height: 600
          )
          id
          title
        }
        name
      }
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      allContentfulGame: allContentfulGame(
        skip: $skip,
        limit: $limit,
        filter: {
          node_locale: { eq: "es-AR" }, 
          expansion: {nin: true},
          cities: {elemMatch: {contentful_id: {eq: $city}}},
          listed: {nin: false}
        },
        sort: {fields: title, order: ASC}
        ) {
        totalCount
        edges {
          node {
            ...GameIndexEntry
          }
        }
      }
  }
`
